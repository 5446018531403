<template>
  <v-card flat tile>
    <!-- {{ favorites }} -->
    <v-card-title>{{ $t("favorites") }}</v-card-title>
    <div class="container">
      <div v-if="favorites != null">
        <div v-if="favorites.length == 0">{{ $t("noFavorites") }}</div>
        <div>
          <thumbnailSolutions :solutions="favorites" :isFirelog="isFirelog" />
          <!-- <div class="wrapper_solutions mt-3">
            <v-card
              outlined
              class="wrapper_content_solution"
              v-for="solution in favorites"
              :key="solution.Id"
            >
              <v-card-text style="height: 100%">
                <v-img contain :src="solution.Link" height="360px"></v-img>
                <div class="title-1 font-weight-bold">
                  {{ solution.Name }}
                </div>
                <div class="title font-weight-bold" v-if="solution.Tax1">
                  {{ $t("diameter") }}: {{ solution.Tax1 }}mm
                </div>
                <div class="subtitle-1 font-weight-bold">
                  {{ $t("fireResistance") }}: {{ solution.Tax6 }}
                  {{ $t("minutes") }}
                </div>
                <div class="subtitle-1 font-weight-bold">
                  {{ $t("thickness") }}: {{ solution.Tax9 }}mm
                </div>
                <div
                  class="subtitle-1 font-weight-bold"
                  v-if="solution.WandDikte"
                >
                  {{ $t("wallThickness") }}: {{ solution.WandDikte }}mm
                </div>
                <div
                  class="subtitle-1 font-weight-bold"
                  v-if="solution.IsolatieDikte"
                >
                  {{ $t("insulationThickness") }}:
                  {{ solution.IsolatieDikte }}mm
                </div>
                <div class="subtitle-1 font-weight-bold">
                  <br v-if="!solution.Tax1" />
                  <br v-if="!solution.WandDikte" />
                  <br v-if="!solution.IsolatieDikte" />
                </div>
                <v-card-actions>
                  <v-btn
                    small
                    depressed
                    outlined
                    color="primary"
                    v-on:click="selectedSolution(solution)"
                  >
                    {{ $t("viewMore") }}
                  </v-btn>
                  <v-btn
                    v-on:click="useInMarker(solution, data.Selected)"
                    small
                    v-if="isFirelog"
                    depressed
                    outlined
                    color="secondary"
                  >
                    {{ $t("usethisinmarker") }}
                  </v-btn>

                  <v-spacer />
                  <v-btn small depressed v-on:click="favorite(solution)">
                    <v-icon small color="primary" v-if="solution.IsFavorite">
                      mdi-star
                    </v-icon>
                    <v-icon small color="primary" v-else>
                      mdi-star-outline
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div> -->
        </div>
      </div>
      <v-row v-else>
        <v-col cols="12" sm="6" lg="4" v-for="index in 6" :key="index">
          <v-skeleton-loader type="card, actions"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import ajax from "@/services/service.oryx.js";
import jwtdecode from "jwt-decode";
import thumbnailSolutions from "@/components/ThumbnailSolutions";

export default {
  props: ["isFirelog"],
  components: {
    thumbnailSolutions,
    // selectedSolutionComponent
  },
  data() {
    return {
      // isFirelog: true,
      favorites: null,
      selectedSolutionId: null,
      tax263415Id: null,
    };
  },
  created() {
    this.$store.commit("resetSelectedSolution");
    this.$store.commit("resetSolutionSelectorChoices");

    const userId = jwtdecode(localStorage.getItem("auth")).id;
    if (userId != null) {
      ajax.getFavorites(userId).then((res) => {
        this.favorites = res.data;
      });
    }
  },
  methods: {
    selectedSolution(solution) {
      this.$router.push({
        name: "Detail",
        params: {
          id: solution.Id,
          taxId: solution.Tax263415Id,
          isFirelog: this.isFirelog,
        },
      });
    },
    useInMarkerFromDetails() {
      this.dialogParent = false;
    },
    favorite(solution) {
      // console.log(solution);
      let user = jwtdecode(localStorage.getItem("auth"));
      const params = {
        userId: user.id,
        Taxonomy26734158Id: solution.Tax263415Id,
      };
      // console.log("Params: ", params);
      ajax.createFavorite(params).then((res) => {
        if (res.data == "Deleted") {
          solution.IsFavorite = false;
        } else if (res.data == "Created") {
          solution.IsFavorite = true;
        }
        // console.log(res);
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
}
.wrapper_options {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.wrapper_content_taxonomy {
  /*--auto-grid-min-size: 25rem;*/
  display: grid;

  grid-template-columns: 1fr 5fr;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_options:hover .wrapper_content_taxonomy {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_options:hover .wrapper_content_taxonomy:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* start solutions */
.wrapper_solutions {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.wrapper_content_solution {
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_solutions:hover .wrapper_content_solution {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_solutions:hover .wrapper_content_solution:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* end solutions */
.option {
  border-radius: 5px;
  padding: 1em;
  height: 100%;
}
.option .image {
  padding: 0;
}
.wrapper_content_taxonomy {
  padding: 0em;
}

.solution {
  border-radius: 5px;
  padding: 1em;
  height: auto;
  min-height: 88px;
}
.sticky_solutionSelector {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
